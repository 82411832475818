import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/filme',
        name: 'Films',
        component: () => import('../views/Films.vue')
    },
    {
        path: '/filme/:slug',
        name: 'Film',
        component: () => import('../views/Film.vue'),
        props: true 
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    mode: 'history'
})

export default router
