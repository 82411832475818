<template>
    <Menu></Menu>
    <router-view></router-view>
</template>

<script>

import Menu from '@/components/MenuComponent.vue';
import store from './store';

export default {
    name: 'App',
    components: {
        Menu
    },
    data(){
        return {
        }
    },
    mounted(){
        store.dispatch('fetchPages');
        store.dispatch('fetchFilms');
    },
    methods: {

    }

}
</script>

<style lang="scss">
    @import "./assets/sass/app.scss";
</style>
