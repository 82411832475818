<template>
    <ul class="menu">
        <li class="h1">
            <router-link :to="{ name: 'Home', params: {} }">Filmallmende</router-link>
        </li>
        <li class="films h1">
            <router-link :to="{ name: 'Films', params: {} }">Films</router-link>
        </li>
        <li class="h1">
            <router-link :to="{ name: 'About', params: {} }">About</router-link>
        </li>
    </ul>
</template>


<script>

export default {
    name: 'Menu',
    props: {
        showMenu: Object
    },
}
</script>
