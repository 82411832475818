import { createStore } from 'vuex'
import { getHttp } from '../http/fetchApi';

export default createStore({
    state: {
        pages: {
            data: []
        },
        films: {
            data: []
        }
    },
    getters: {
        getPages: state => {
            return state.pages.data;
        },
        getFilms: (state) => {
            return state.films.data;
        },
        getPageBySlug: (state) => (slug) => {
            var pages = state.pages.data.filter( page => (page.slug == slug) );
            return pages.length ? pages[0] : null;
        },
        getFilmBySlug: (state) => (slug) => {
            var films = state.films.data.filter( film => (film.slug == slug) );
            return films.length ? films[0] : null;
        },
    },
    mutations: {
        setPages: (state, data) => {
            state.pages.data = data;
        },
        setFilms: (state, data) => {
            state.films.data = data;
        }
    },
    actions: {
        async fetchPages({ commit }) {
            const { data } = await getHttp("/api/pages.json");
            commit('setPages', data.data);
        },
        async fetchFilms({ commit }) {
            const { data } = await getHttp("/api/films.json");
            commit('setFilms', data.data);
        }
    },
})
